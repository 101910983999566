<template>
  <v-main app>
    <v-container fluid>
      <customers />
    </v-container>
  </v-main>
</template>

<script>
import Customers from '@/views/Admin/Business/Customer'

export default {
  components: {
    Customers
  },
  data () {
    return {
    }
  },
  async created () {
  },
  computed: {
  },
  methods: {
  }
}
</script>

