<template>
  <v-container fluid>
    <v-row class="d-flex justify-space-between">
      <v-col>
        <h1 class="display-1 font-weight-bold mx-1 mb-3">
          Browse Customers ({{ totalUsers }})
        </h1>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="searchText"
          label="Search"
          dense
          outlined
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <p>Note that login count is calculated starting from 30/1/2022</p>
    <v-data-table
      :headers="headers"
      :items="users"
      :disable-pagination="true"
      :sort-by="['lastLogin']"
      :sort-desc="[true]"
      :hide-default-footer="true"
      :search="searchText"
      :loading="loadingData"
      loading-text="Loading customers... Please wait"
      @current-items="setTotal"
      dense
    >
      <template v-slot:item="{ item, index }">
        <tr>
          <td>{{ index + 1 }}</td>
          <td>{{ item.customerName }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.numCartItems }}</td>
          <td>{{ item.numOrders }}</td>
          <td>
            <a
              target="_blank"
              v-bind:href="
                'https://go.xero.com/Contacts/View/' + item.xeroContactId
              "
              >{{ item.accountNumber }}</a
            >
          </td>
          <td>{{ item.loginCount }}</td>
          <td>
            {{
              item.lastLogin
                ? new Date(Date.parse(item.lastLogin)).toLocaleDateString()
                : null
            }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import User from "@/api/user";

export default {
  components: {},
  data() {
    return {
      searchText: null,
      loadingData: true,
      users: [],
      totalUsers: 0,
      headers: [
        {
          text: "#",
          value: "index",
        },
        {
          text: "Name",
          value: "customerName",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Cart",
          value: "numCartItems",
          width: 100,
        },
        {
          text: "Orders",
          value: "numOrders",
          width: 100,
        },
        {
          text: "Xero a/c",
          value: "accountNumber",
          width: 100,
        },
        {
          text: "Logins",
          value: "loginCount",
          width: 100,
        },
        {
          text: "Last login",
          value: "lastLogin",
          width: 100,
        },
      ],
    };
  },
  async created() {
    User.getUsers()
      .then((results) => {
        this.users = results.data
        this.loadingData = false;
      })
      .catch((err) => {
        console.log("err", err);
        this.loadingData = false;
      });
  },
  computed: {},
  watch: {},
  methods: {
    setTotal(items) {
      this.totalUsers = items.length;
    },
  },
};
</script>
<style scoped>
::v-deep .test {
  white-space: nowrap;
}
</style>
